// extracted by mini-css-extract-plugin
export var cartIcon = "header-module--cartIcon--AW3TD";
export var cartIconMobile = "header-module--cartIconMobile--pR3mQ";
export var header = "header-module--header--lkiQn";
export var logoutButton = "header-module--logoutButton--Oiqi5";
export var mainNav = "header-module--mainNav--ayb8v";
export var navClose = "header-module--navClose--Z3BqB typography--crossButton--5BLK4";
export var navHomeLink = "header-module--navHomeLink--Agn7v";
export var navIsOpen = "header-module--navIsOpen--Pq5j5";
export var navLinkActive = "header-module--navLinkActive--PDKow";
export var navToggle = "header-module--navToggle--a+HsH";
export var navToggleOpen = "header-module--navToggleOpen--BNSeG";
export var navWrap = "header-module--navWrap--WN1Kv";
export var regionSelect = "header-module--regionSelect--jiUWC";
export var shopNav = "header-module--shopNav--4PL2u";
export var spacer = "header-module--spacer--wM2jF";