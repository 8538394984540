// extracted by mini-css-extract-plugin
export var button = "forms-module--button--ZipCt";
export var checkboxUi = "forms-module--checkboxUi--A4hhf";
export var checkboxUiDark = "forms-module--checkboxUiDark--67cPV";
export var checkboxWrap = "forms-module--checkboxWrap--T1KiH";
export var errorMessage = "forms-module--errorMessage--tCXii";
export var generalForm = "forms-module--generalForm--aoMYa";
export var inputError = "forms-module--inputError--HPts2";
export var inputSolidBg = "forms-module--inputSolidBg--lGVHo";
export var inputWrap = "forms-module--inputWrap--RSIGf";
export var radioUi = "forms-module--radioUi--10Xy3";
export var radioUiDark = "forms-module--radioUiDark--iMtp-";
export var radioWrap = "forms-module--radioWrap--u3Wmm";
export var selectWrap = "forms-module--selectWrap--apWPH";
export var spin = "forms-module--spin--Ifk9z";
export var splitFieldset = "forms-module--splitFieldset--J4vxw";
export var stripeCardElementWrap = "forms-module--stripeCardElementWrap--fYFw8";
export var stripeCardFormSubmit = "forms-module--stripeCardFormSubmit--2LO0s forms-module--button--ZipCt";
export var submitButton = "forms-module--submitButton--TQlCw forms-module--button--ZipCt";
export var successMessage = "forms-module--successMessage--N6LVz";