exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-shop-account-index-js": () => import("./../../../src/pages/shop/account/index.js" /* webpackChunkName: "component---src-pages-shop-account-index-js" */),
  "component---src-pages-shop-account-order-history-js": () => import("./../../../src/pages/shop/account/order-history.js" /* webpackChunkName: "component---src-pages-shop-account-order-history-js" */),
  "component---src-pages-shop-account-sign-in-js": () => import("./../../../src/pages/shop/account/sign-in.js" /* webpackChunkName: "component---src-pages-shop-account-sign-in-js" */),
  "component---src-pages-shop-account-sign-up-js": () => import("./../../../src/pages/shop/account/sign-up.js" /* webpackChunkName: "component---src-pages-shop-account-sign-up-js" */),
  "component---src-pages-shop-checkout-js": () => import("./../../../src/pages/shop/checkout.js" /* webpackChunkName: "component---src-pages-shop-checkout-js" */),
  "component---src-pages-shop-order-confirmed-js": () => import("./../../../src/pages/shop/order-confirmed.js" /* webpackChunkName: "component---src-pages-shop-order-confirmed-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-mdx-js": () => import("./../../../src/templates/mdx.js" /* webpackChunkName: "component---src-templates-mdx-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

