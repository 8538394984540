// extracted by mini-css-extract-plugin
export var cartEmptyMessage = "cart-tray-module--cartEmptyMessage--AH-Cw";
export var cartIcon = "cart-tray-module--cartIcon--zZBUN";
export var cartItems = "cart-tray-module--cartItems--cGZKj";
export var cartReview = "cart-tray-module--cartReview--m9QE7";
export var cartTotal = "cart-tray-module--cartTotal--bnd9K";
export var checkoutButton = "cart-tray-module--checkoutButton--kDazS forms-module--button--ZipCt";
export var tray = "cart-tray-module--tray--5zMG5";
export var trayClose = "cart-tray-module--trayClose--8upS5 typography--crossButton--5BLK4";
export var trayIsOpen = "cart-tray-module--trayIsOpen--wvrL2";
export var trayItem = "cart-tray-module--trayItem--Pa0Tz";